<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-4">
          <v-autocomplete v-model="filter.task_ids"
                          :disabled="taskLoading"
                          :items="tasks"
                          :loading="taskLoading"
                          chips
                          class="mb-3" clearable deletable-chips hide-details
                          item-text="name"
                          item-value="id"
                          label="Tâches"
                          multiple
                          small-chips/>

        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 depressed
                 @click="handleFilter">
            <v-icon left>mdi-magnify</v-icon>
            Recherche
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            taskLoading: false,
            tasks: null,
            filter: {},
        }
    },
    methods: {
        handleFilter() {
            this.close()
            this.$emit('handle-filter', this.filter)
        },
        open() {
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        getCampaignTasks() {
            this.taskLoading = true
            this.$Progress.start()
            HTTP.get('/analytic/campaign/tasks', {
                params: {
                    campaign_id: parseInt(this.$route.params.id)
                }
            }).then((res) => {
                this.taskLoading = false
                this.$Progress.finish()
                this.tasks = res.data.data
                this.campaign = res.data.data.campaign
            }).catch(err => {
                this.$Progress.fail()
                this.taskLoading = false
                console.log(err)
            })
        }
    },
}
</script>

<style scoped>

</style>