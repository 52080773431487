<template>
  <div>
    <v-container fluid>

      <FilterDialog ref="filterDialog" @handle-filter="getFurnituresAnalyticByRegin($event)"/>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="rounded-lg transparent" flat>
            <v-tabs>
              <v-tab class="b-700" disabled>
                <v-skeleton-loader v-if="!campaign" type="button"/>
                <v-skeleton-loader v-if="!campaign" type="button" class="ml-2"/>
                <span v-else class="text-no-wrap">
                <span class="text--primary">Analyse de campagne : </span>   {{ campaign.name }}
                </span>
              </v-tab>
            </v-tabs>
            <v-spacer/>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       class="mt-3 mr-1"
                       @click="refresh">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Actualiser</span>
            </v-tooltip>
            <v-btn rounded depressed outlined
                   @click="openDialogFilter"
                   color="primary"
                   class="mt-3 mr-1">
              <v-icon left>mdi-filter-outline</v-icon>
              Filtrer
            </v-btn>
            <v-btn rounded depressed
                   color="primary"
                   class="mt-3"
                   @click="$router.push('/campaigns')">
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row v-if="isLoading">
        <v-col cols="12" lg="6" v-for="(item,i) in 6" :key="i">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="image,article"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>

        <v-col cols="12" lg="6" v-for="(furniture,i) in furnitureKeys" :key="i">
          <v-card class="rounded-lg shadow"
                  :loading="furniture.loading"
                  :disabled="furniture.loading">
            <v-card-title>
              <v-toolbar-title class="fs-18">{{ keyword[furniture.name] }}</v-toolbar-title>
              <v-spacer/>

              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon
                         v-bind="attrs"
                         v-on="on"
                         @click="downloadImage(furniture.name,campaign.name+'-'+keyword[furniture.name])">
                    <v-icon>mdi-image</v-icon>
                  </v-btn>
                </template>
                <span>Exporter on Image</span>
              </v-tooltip>

              <download-excel :data="getExcelJsonData(results.regions,results.results[i])"
                              :title="'CAMPAIGNE : '+campaign.name + ' / ' +keyword[furniture.name]"
                              :name="campaign.name + '-' +keyword[furniture.name]">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exporter on excel</span>
                </v-tooltip>
              </download-excel>

            </v-card-title>
            <v-card-text>

              <v-btn-toggle v-model="furniture.tab" mandatory tile group color="blue"
                            active-class="active--tab">

                <v-btn class="text-none" :value="0">
                  <v-icon left>mdi-chart-bar</v-icon>
                  Barre graphique
                </v-btn>

                <v-btn class="text-none" :value="1">
                  <v-icon left>mdi-table</v-icon>
                  Tableau
                </v-btn>

              </v-btn-toggle>

              <div v-if="!furniture.tab" class="mt-5">
                <MultipleBarChart
                    v-if="results"
                    :title="keyword[furniture.name]"
                    :chart-id="furniture.name"
                    :data="results.results[i]"
                    :keys="results.regions"
                    :labels="results.labels"/>
              </div>

              <div v-if="furniture.tab" class="mt-5">
                <v-simple-table class="table-border">
                  <template v-slot:default>
                    <tbody>

                    <tr>
                      <th class="text-center"></th>
                      <th class="text-center"><strong>Planogramme</strong></th>
                      <th class="text-center"><strong>Stock</strong></th>
                    </tr>

                    <tr v-for="(region,index) in results.regions" :key="index">
                      <td>
                        <strong>{{ region }}</strong>
                      </td>
                      <td class="text-center">{{ results.results[i]['Planogramme'][index] }}%</td>
                      <td class="text-center">{{ results.results[i]['Stock'][index] }}%</td>
                    </tr>

                    <tr>
                      <th><strong>National</strong></th>
                      <th class="text-center">
                        <strong class="th-font">{{ planogrammeAverage(results.results[i]['Planogramme']) }}</strong>
                      </th>
                      <th class="text-center">
                        <strong class="th-font">{{ stockAverage(results.results[i]['Stock']) }}</strong>
                      </th>
                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import MultipleBarChart from "../../components/Chart/MultipleBarChart";
import {HTTP} from "@/http-common";
import FilterDialog from "@/views/analytics/components/FilterDialog";


export default {
  components: {
    FilterDialog,
    MultipleBarChart,
  },
  data() {
    return {
      isLoading: false,
      overlay: false,
      results: null,
      campaign: null,
      campaign_id: null,
      keyword: this.$store.state.keyword,
      tab: null,
      furnitureKeys: [
        {name: 'key_10', tab: 0},
        {name: 'key_11', tab: 0},
        {name: 'key_12', tab: 0},
        {name: 'key_13', tab: 0},
        // {name: 'key_14', tab: 0},
        // {name: 'key_15', tab: 0},
        // {name: 'key_16', tab: 0},
        // {name: 'key_17', tab: 0},
        // {name: 'key_18', tab: 0},
        // {name: 'key_19', tab: 0},
        // {name: 'key_20', tab: 0},
        // {name: 'key_21', tab: 0},
        // {name: 'key_22', tab: 0},
        // {name: 'key_23', tab: 0},
        // {name: 'key_24', tab: 0},
        // {name: 'key_25', tab: 0}
      ],
    }
  },
  methods: {
    getFurnituresAnalyticByRegin(el) {
      let filter = el ? el : null
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('/analytic/furnitures/region', {
        params: {
          campaign_id: this.campaign_id,
          filter: filter,
        }
      }).then((res) => {
        this.isLoading = false
        this.$Progress.finish()
        this.results = res.data.data.results
        this.campaign = res.data.data.campaign
      }).catch(err => {
        this.$Progress.fail()
        this.isLoading = false
        console.log(err)
      })
    },
    planogrammeAverage(arr) {
      return arr.reduce((a, b) => a + b, 0) / this.results.regions.length + '%'
    },
    stockAverage(arr) {
      return arr.reduce((a, b) => a + b, 0) / this.results.regions.length + '%'
    },
    downloadImage(id, name) {
      MultipleBarChart.methods.downloadImage(id, name)
    },
    getExcelJsonData(regions, results) {
      let json = []
      for (let i = 0; i < regions.length; i++) {
        json.push({
          'Région': regions[i],
          'Planogramme': results['Planogramme'][i] + '%',
          'Stock': results['Stock'][i] + '%',
        })
      }
      json.push({
        'Région': "National",
        'Planogramme': this.planogrammeAverage(results['Planogramme']),
        'Stock': this.stockAverage(results['Stock']),
      })
      return json
    },
    openDialogFilter() {
      this.$refs.filterDialog.getCampaignTasks()
      this.$refs.filterDialog.open()
    },
    refresh() {
      this.getFurnituresAnalyticByRegin()
    }
  },
  created() {
    this.campaign_id = this.$route.params.id
    if (this.campaign_id)
      this.getFurnituresAnalyticByRegin()
  },
}
</script>

<style scoped>
.th-font {
  font-size: .875rem;
}

.active--tab {
  border-top: 2px solid #3DB2FF !important;
}
</style>