<template>
  <div>
    <Bar :chart-options="chartOptions"
         @downlod="downloadImage"
         :chart-data="chartData"
         :chart-id="chartId"/>
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)
ChartJS.defaults.font.family = "Quicksand";

export default {
  name: 'BarChart',
  components: {Bar},
  props: {
    data: {
      type: Object,
      default: null
    },
    chartId: {
      type: String,
      default: null
    },
    keys: {
      type: Array,
      default: null
    },
    labels: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      chartData: {
        labels: this.keys,
        datasets: [
          {
            label: this.labels[0],
            data: this.data[this.labels[0]],
            backgroundColor: "#3DB2FF",
            borderColor: "#3DB2FF",
            borderRadius: 50,
          },
          {
            label: this.labels[1],
            data: this.data[this.labels[1]],
            backgroundColor: "#1783cb",
            borderColor: "#1783cb",
            borderRadius: 50,
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 45
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            max: 100,
          }
        },
        plugins: {
          title: {
            display: true,
            text: this.title,
            position: 'bottom',
          },
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 10,
            },
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              labelPointStyle: function () {
                return {
                  pointStyle: 'rounded',
                };
              }
            }
          },
          datalabels: {
            align: 'end',
            anchor: 'end',
            borderRadius: 4,
            color: 'white',
            rotation: -40,
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            padding: 4,
          }
        },
      }
    }
  },
  methods: {
    downloadImage(id,name) {
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name
      link.href = canvas
      link.click()

    }
  },
}
</script>
